import React, {
    useEffect,
    useRef,
    useState,
    useCallback,
    useMemo,
} from 'react';
import Button from './components/Button/Button';
import Modal from './components/Modal/Modal';
import QuickView from './components/QuickView/QuickView';
import DragTooltip from './components/DragTooltip/DragTooltip';
import HotspotDetails from './components/HotspotDetails/HotspotDetails';
import { getProduct } from './services/ContentfulService';
import FrameViewer from './components/FrameViewer/FrameViewer';
import AdminMode from './components/AdminMode/AdminMode';
import './styles/global.css';
import styles from './App.module.css';

const App = () => {
    const pathParts = decodeURIComponent(window.location.pathname)
        .replace(/^\//, '')
        .split('/');
    const productSlug = pathParts[0]?.replace(/\s/g, '-') || 'casper';
    const adminMode = pathParts[1] === 'admin';

    const [product, setProduct] = useState(null);
    const [frameImages, setFrameImages] = useState([]);
    const [loadingProgress, setLoadingProgress] = useState(0);
    const [frameIndex, setFrameIndex] = useState(0);
    const [modalOpen, setModalOpen] = useState(false);
    const [showDragTooltip, setShowDragTooltip] = useState(false);
    const [showHotspotDetails, setShowHotspotDetails] = useState(false);
    const [activeHotspot, setActiveHotspot] = useState(null);
    const [error, setError] = useState(null);
    const [adminText, setAdminText] = useState(null);
    const [hotspotsEnabled, setHotspotsEnabled] = useState(true);

    const animationIntervalRef = useRef(null);

    // Function to handle hotspot clicks
    const clickHotspot = useCallback(
        hotspot => {
            if (hotspot.id === activeHotspot?.id) {
                setTimeout(() => {
                    setActiveHotspot(null);
                }, 300);
                setShowHotspotDetails(false);
            } else {
                setActiveHotspot(hotspot);
                setShowHotspotDetails(true);
            }
        },
        [activeHotspot]
    );

    const fetchProduct = useCallback(async () => {
        try {
            const productData = await getProduct(productSlug);
            if (productData) {
                // Sort frames before mapping
                productData.frames.sort((a, b) => {
                    const aFileName = a.file?.fileName || '';
                    const bFileName = b.file?.fileName || '';
                    return aFileName.localeCompare(bFileName);
                });

                // Map frame URLs
                const frameUrls = productData.frames.map(
                    frame => `${frame.file.url}?fm=webp&q=70`
                );

                // Convert hotspot frames to integers
                productData.hotspots?.forEach(hotspot => {
                    hotspot.frames = hotspot.frames?.map(frame =>
                        parseInt(frame)
                    );
                });

                setProduct({ ...productData, frameUrls });
            }
        } catch (err) {
            console.error('Error fetching product:', err);
            setError('Error loading product');
        }
    }, [productSlug]);

    const productQuickViewItems = useMemo(() => {
        if (!product?.hotspots) return [];
        const items = [];
        product.hotspots.forEach((hotspot, index) => {
            if (hotspot.frames && hotspot.hotspotDetail) {
                const isUnique = !items.some(
                    item => item.hotspotId === hotspot.hotspotDetail.id
                );
                if (isUnique) {
                    items.push({
                        hotspotId: hotspot.hotspotDetail.id, // Include hotspotId for uniqueness check
                        name: hotspot.hotspotDetail.title,
                        frameIndex: hotspot.frames[0], // Ensure frameIndex is a number
                        hotspotIndex: index,
                        image: `${
                            hotspot.hotspotDetail.thumbnail?.file.url ||
                            hotspot.hotspotDetail.images?.[0]?.file.url ||
                            ''
                        }?w=100&fm=webp&q=70`,
                    });
                }
            }
        });
        return items;
    }, [product]);

    const loadFrameImages = useCallback(async () => {
        if (!product?.frameUrls) return;
        try {
            let loadedCount = 0;
            const images = await Promise.all(
                product.frameUrls.map(
                    url =>
                        new Promise((resolve, reject) => {
                            const img = new Image();
                            img.src = url;
                            img.onload = () => {
                                loadedCount++;
                                setLoadingProgress(
                                    (loadedCount / product.frameUrls.length) *
                                        100
                                );
                                resolve(img);
                            };
                            img.onerror = reject;
                        })
                )
            );
            setFrameImages(images);
        } catch (err) {
            console.error('Error loading images:', err);
            setError('Error loading images');
        }
    }, [product]);

    const animateTo = useCallback(
        (targetIndex, callback) => {
            if (animationIntervalRef.current)
                clearInterval(animationIntervalRef.current);

            let nextIndex = frameIndex;
            const totalFrames = frameImages.length;

            const forwardDistance =
                (targetIndex - frameIndex + totalFrames) % totalFrames;
            const backwardDistance =
                (frameIndex - targetIndex + totalFrames) % totalFrames;
            const forward = forwardDistance <= backwardDistance;

            animationIntervalRef.current = setInterval(() => {
                nextIndex =
                    (nextIndex + (forward ? 1 : -1) + totalFrames) %
                    totalFrames;
                setFrameIndex(nextIndex);
                if (nextIndex === targetIndex) {
                    clearInterval(animationIntervalRef.current);
                    animationIntervalRef.current = null;
                    callback?.();
                }
            }, 500 / totalFrames);
        },
        [frameIndex, frameImages.length]
    );

    const openModal = () => {
        setModalOpen(true);
        setShowDragTooltip(true);
        setTimeout(() => setShowDragTooltip(false), 3000);
        window.top?.postMessage('enter-360', '*');
    };

    const closeModal = () => {
        setModalOpen(false);
        window.top?.postMessage('exit-360', '*');
    };

    useEffect(() => {
        fetchProduct();
    }, [fetchProduct]);

    useEffect(() => {
        loadFrameImages();
    }, [loadFrameImages]);

    if (error) {
        return <div>{error}</div>;
    }

    if (!product || frameImages.length === 0) {
        return (
            <div className={styles.loaderContainer}>
                <div className={styles.loader}>
                    <div
                        className={styles.progress}
                        style={{
                            width: `${loadingProgress}%`,
                        }}
                    />
                </div>
            </div>
        );
    }

    return (
        <div
            className={styles.coverImage}
            style={{ backgroundImage: `url(${product.coverImage.file.url})` }}
        >
            <Modal onClose={closeModal} open={modalOpen}>
                {adminMode && (
                    <AdminMode
                        frameIndex={frameIndex}
                        hotspotsEnabled={hotspotsEnabled}
                        toggleHotspots={() => setHotspotsEnabled(prev => !prev)}
                        text={adminText}
                    />
                )}
                <FrameViewer
                    frame={frameImages[frameIndex]}
                    frameIndex={frameIndex}
                    totalFrames={frameImages.length}
                    adminMode={adminMode}
                    setAdminText={setAdminText}
                    hotspots={hotspotsEnabled ? product.hotspots : []}
                    activeHotspot={activeHotspot}
                    clickHotspot={clickHotspot}
                    hideDragTooltip={() => setShowDragTooltip(false)}
                    setFrameIndex={setFrameIndex}
                />
                <DragTooltip show={showDragTooltip} />
                <HotspotDetails
                    onClose={() => {
                        setShowHotspotDetails(false);
                        setActiveHotspot(null);
                    }}
                    show={showHotspotDetails}
                    body={activeHotspot?.hotspotDetail?.body ?? ''}
                    title={activeHotspot?.hotspotDetail?.title ?? ''}
                    thumbnail={activeHotspot?.hotspotDetail?.thumbnail ?? null}
                    links={activeHotspot?.hotspotDetail?.links ?? []}
                    resources={activeHotspot?.hotspotDetail?.resources ?? []}
                    footnotes={activeHotspot?.hotspotDetail?.footnotes ?? []}
                    images={activeHotspot?.hotspotDetail?.images ?? []}
                />
                {product.hotspots?.length > 0 && (
                    <QuickView
                        items={productQuickViewItems}
                        onChooseItem={(frameIndex, hotspotIndex) => {
                            setShowHotspotDetails(false);
                            animateTo(frameIndex, () =>
                                clickHotspot(product.hotspots[hotspotIndex])
                            );
                        }}
                    />
                )}
            </Modal>
            <Button onClick={openModal}>Launch Viewer</Button>
        </div>
    );
};

export default App;
