import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from './Hotspot.module.css';

const Hotspot = ({ active, onClick, x, y }) => (
    <button
        className={cx(styles.button, active && styles.active)}
        style={{ left: `${x}%`, top: `${y}%` }}
        onClick={onClick}
    />
);

Hotspot.propTypes = {
    active: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
};

export default Hotspot;
