import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Button from '../Button/Button';

import styles from './AdminMode.module.css';

const AdminMode = ({ frameIndex, text, toggleHotspots, hotspotsEnabled }) => {
    let [copied, setCopied] = useState(false);
    return (
        <div className={styles.root}>
            Admin Mode
            <div className={styles.message}>Current frame: {frameIndex}</div>
            {text && <div className={styles.message}>{text}</div>}
            <Button
                className={cx(styles.copyEmbedCode, copied && styles.copied)}
                onClick={() => {
                    setCopied(true);
                    let textField = document.createElement('textarea');
                    textField.innerText = `<iframe src="${window.location.href.replace(
                        /\/admin\/?/,
                        ''
                    )}" height="600px" width="100%"></iframe>`;
                    document.body.appendChild(textField);
                    textField.select();
                    document.execCommand('copy');
                    textField.remove();
                    setTimeout(function () {
                        setCopied(false);
                    }, 2000);
                }}
                size={'small'}
            >
                {copied ? 'Copied!' : 'Copy Embed Link'}
            </Button>
            <Button
                className={styles.disableHotspots}
                onClick={toggleHotspots}
                size={'small'}
            >{`${hotspotsEnabled ? 'Disable' : 'Enable'} Hotspots`}</Button>
        </div>
    );
};

AdminMode.propTypes = {
    frameIndex: PropTypes.number.isRequired,
    text: PropTypes.string,
    toggleHotspots: PropTypes.func,
    hotspotsEnabled: PropTypes.bool,
};

export default AdminMode;
