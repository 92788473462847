import { createClient } from 'contentful';

const client = createClient({
    space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
    accessToken: process.env.REACT_APP_CONTENTFUL_TOKEN,
});

const cleanupContentfulData = data => {
    let id = data?.sys?.id;
    if (data?.fields) {
        data = data?.fields;
        data.id = id;
    }
    for (let key in data) {
        let child = data[key];
        if (child.fields) {
            data[key] = cleanupContentfulData(child);
        } else if (Array.isArray(child)) {
            for (let childKey in child) {
                let grandchild = child[childKey];
                if (grandchild.fields) {
                    child[childKey] = cleanupContentfulData(grandchild);
                }
            }
        }
    }
    return data;
};

export const getProduct = slug => {
    return new Promise((resolve, reject) => {
        client
            .getEntries({
                content_type: 'product',
                'fields.slug': slug,
                limit: 1,
                include: 3,
            })
            .then(data => resolve(cleanupContentfulData(data.items[0])))
            .catch(err => reject(err));
    });
};
