import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import ReactMarkdown from 'react-markdown';
import supersub from 'remark-supersub';

import DownloadIcon from '../../assets/images/download.svg';
import styles from './HotspotDetails.module.css';

const HotspotDetails = ({
    show = false,
    title = '',
    body = '',
    resources = [],
    images = [],
    links = [],
    footnotes = [],
    thumbnail = null,
    onClose,
}) => {
    const currentMedia = images[0] || {};
    const contentType = currentMedia?.file?.contentType || '';

    return (
        <div
            className={cx(styles.root, { [styles.show]: show })}
            onClick={onClose}
        >
            <div className={styles.box} onClick={e => e.stopPropagation()}>
                <button
                    className={styles.close}
                    onClick={onClose}
                    aria-label="Close"
                    type="button"
                />
                {title && <div className={styles.title}>{title}</div>}
                {body && (
                    <ReactMarkdown
                        className={styles.body}
                        remarkPlugins={[supersub]}
                    >
                        {body}
                    </ReactMarkdown>
                )}
                {(resources.length > 0 || links.length > 0) && (
                    <div className={styles.resources}>
                        <div className={styles.resourcesTitle}>Resources</div>
                        {resources.map(resource => (
                            <a
                                className={styles.resource}
                                key={resource.id || resource.title}
                                href={resource.file.url}
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                {resource.title}
                                <img alt="Download Icon" src={DownloadIcon} />
                            </a>
                        ))}
                        {links.map(link => (
                            <a
                                key={link.id || link.title}
                                className={styles.resource}
                                href={link.url}
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                {link.title}
                            </a>
                        ))}
                    </div>
                )}
                {images.length > 0 && (
                    <div className={styles.mediaContainer}>
                        {contentType.startsWith('image') && (
                            <img
                                className={styles.media}
                                src={`${currentMedia.file.url}?w=720&fm=webp&q=70`}
                                alt={currentMedia.description || ''}
                                width={currentMedia.file.details.image.width}
                                height={currentMedia.file.details.image.height}
                            />
                        )}
                        {contentType.startsWith('video') && (
                            <video
                                className={styles.media}
                                src={currentMedia.file.url}
                                preload="metadata"
                                poster={thumbnail?.file?.url || ''}
                                controls
                            />
                        )}
                        {currentMedia.description && (
                            <div className={styles.caption}>
                                {currentMedia.description}
                            </div>
                        )}
                    </div>
                )}
                {footnotes.length > 0 && (
                    <div className={styles.footnotes}>
                        {footnotes.map((footnote, index) => (
                            <div key={index} className={styles.footnote}>
                                <div className={styles.footnoteNumber}>
                                    {index + 1}
                                </div>
                                <div className={styles.footnoteText}>
                                    {footnote}
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

HotspotDetails.propTypes = {
    show: PropTypes.bool,
    title: PropTypes.string,
    body: PropTypes.string,
    resources: PropTypes.arrayOf(PropTypes.object),
    images: PropTypes.arrayOf(PropTypes.object),
    links: PropTypes.arrayOf(PropTypes.object),
    footnotes: PropTypes.arrayOf(PropTypes.string),
    thumbnail: PropTypes.object,
    onClose: PropTypes.func.isRequired,
};

export default HotspotDetails;
