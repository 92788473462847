import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Close from './Close/Close';

import styles from './Modal.module.css';

const Modal = ({ children, open, onClose }) => (
    <div className={cx(styles.root, open && styles.open)}>
        <Close onClick={onClose} />
        {children}
    </div>
);

Modal.propTypes = {
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.element])
        .isRequired,
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
};

export default Modal;
