import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';

const root = ReactDOM.createRoot(
    document.body.appendChild(
        Object.assign(document.createElement('div'), {
            id: 'root',
            style: { width: '100%', height: '100%' },
        })
    )
);

root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);
