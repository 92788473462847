import React, { useEffect, useRef, useCallback } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import Hotspot from '../Hotspot/Hotspot';
import styles from './FrameViewer.module.css';

const FrameViewer = ({
    frame,
    totalFrames,
    frameIndex,
    setFrameIndex,
    adminMode,
    setAdminText,
    hotspots,
    activeHotspot,
    clickHotspot,
    hideDragTooltip,
}) => {
    const imageRef = useRef(null);
    const containerRef = useRef(null);
    const frameIndexRef = useRef(frameIndex);
    const draggingRef = useRef(false);
    const startMouseXRef = useRef(0);
    const startFrameIndexRef = useRef(0);
    const firstDragRef = useRef(true);
    const clipboardTimeoutRef = useRef(null);

    // Keep frameIndexRef updated
    useEffect(() => {
        frameIndexRef.current = frameIndex;
    }, [frameIndex]);

    const updateFrameIndex = useCallback(
        newIndex => {
            frameIndexRef.current = newIndex;
            setFrameIndex(newIndex);
        },
        [setFrameIndex]
    );

    const handleKeyDown = useCallback(
        event => {
            if (event.key === 'ArrowLeft' || event.keyCode === 37) {
                updateFrameIndex(
                    frameIndexRef.current > 0
                        ? frameIndexRef.current - 1
                        : totalFrames - 1
                );
            } else if (event.key === 'ArrowRight' || event.keyCode === 39) {
                updateFrameIndex(
                    frameIndexRef.current < totalFrames - 1
                        ? frameIndexRef.current + 1
                        : 0
                );
            }
        },
        [totalFrames, updateFrameIndex]
    );

    const handleCopyCoordinates = useCallback(
        event => {
            event.preventDefault();
            const img = imageRef.current;
            if (!img) return;

            const rect = img.getBoundingClientRect();
            const x = ((event.clientX - rect.left) / img.clientWidth) * 100;
            const y = ((event.clientY - rect.top) / img.clientHeight) * 100;
            const coordinates = `${x.toFixed(4)},${y.toFixed(4)}`;

            navigator.clipboard.writeText(coordinates).then(() => {
                setAdminText(`Copied to clipboard: ${coordinates}`);
                if (clipboardTimeoutRef.current) {
                    clearTimeout(clipboardTimeoutRef.current);
                }
                clipboardTimeoutRef.current = setTimeout(() => {
                    setAdminText(null);
                }, 3000);
            });
        },
        [setAdminText]
    );

    const handleDragStart = useCallback(event => {
        event.preventDefault();
        draggingRef.current = true;
        startMouseXRef.current = event.type.includes('touch')
            ? event.touches[0].pageX
            : event.pageX;
        startFrameIndexRef.current = frameIndexRef.current;
    }, []);

    const handleDragMove = useCallback(
        event => {
            if (!draggingRef.current) return;

            if (firstDragRef.current) {
                firstDragRef.current = false;
                hideDragTooltip();
            }

            const currentMouseX = event.type.includes('touch')
                ? event.touches[0].pageX
                : event.pageX;
            const deltaX = currentMouseX - startMouseXRef.current;
            const containerWidth = containerRef.current.offsetWidth;
            const framesPerPixel = totalFrames / containerWidth;
            const frameOffset = Math.round(deltaX * framesPerPixel * 1.5);
            let newFrameIndex =
                (startFrameIndexRef.current + frameOffset) % totalFrames;
            if (newFrameIndex < 0) newFrameIndex += totalFrames;

            if (newFrameIndex !== frameIndexRef.current) {
                updateFrameIndex(newFrameIndex);
            }
        },
        [hideDragTooltip, totalFrames, updateFrameIndex]
    );

    const handleDragEnd = useCallback(() => {
        draggingRef.current = false;
    }, []);

    useEffect(() => {
        const img = imageRef.current;
        const container = containerRef.current;

        // Keyboard events
        document.addEventListener('keydown', handleKeyDown);

        // Drag events
        container.addEventListener('mousedown', handleDragStart);
        container.addEventListener('touchstart', handleDragStart, {
            passive: false,
        });
        document.addEventListener('mousemove', handleDragMove);
        document.addEventListener('touchmove', handleDragMove, {
            passive: false,
        });
        document.addEventListener('mouseup', handleDragEnd);
        document.addEventListener('touchend', handleDragEnd);

        // Admin mode
        if (adminMode) {
            img.addEventListener('click', handleCopyCoordinates);
        }

        // Cleanup
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
            container.removeEventListener('mousedown', handleDragStart);
            container.removeEventListener('touchstart', handleDragStart);
            document.removeEventListener('mousemove', handleDragMove);
            document.removeEventListener('touchmove', handleDragMove);
            document.removeEventListener('mouseup', handleDragEnd);
            document.removeEventListener('touchend', handleDragEnd);

            if (adminMode) {
                img.removeEventListener('click', handleCopyCoordinates);
            }
        };
    }, [
        handleKeyDown,
        handleDragStart,
        handleDragMove,
        handleDragEnd,
        adminMode,
        handleCopyCoordinates,
    ]);

    // Filter hotspots for the current frame
    const currentHotspots = hotspots.filter(hotspot =>
        hotspot.frames.includes(frameIndex)
    );

    return (
        <div className={styles.root} ref={containerRef}>
            {currentHotspots.map((hotspot, index) => {
                const [x, y] = hotspot.position
                    .split(',')
                    .map(coord => parseFloat(coord.trim()));
                return (
                    <Hotspot
                        key={hotspot.id || index}
                        onClick={e => {
                            e.stopPropagation();
                            clickHotspot(hotspot);
                        }}
                        active={activeHotspot?.id === hotspot.id}
                        x={x}
                        y={y}
                    />
                );
            })}
            <img
                ref={imageRef}
                src={frame.src}
                className={cx(styles.frame)}
                alt="Frame"
                width={frame.width}
                height={frame.height}
                draggable={false}
            />
        </div>
    );
};

FrameViewer.propTypes = {
    frame: PropTypes.object.isRequired,
    frameIndex: PropTypes.number.isRequired,
    totalFrames: PropTypes.number.isRequired,
    setFrameIndex: PropTypes.func.isRequired,
    adminMode: PropTypes.bool.isRequired,
    setAdminText: PropTypes.func.isRequired,
    hotspots: PropTypes.array.isRequired,
    activeHotspot: PropTypes.object,
    clickHotspot: PropTypes.func.isRequired,
    hideDragTooltip: PropTypes.func.isRequired,
};

export default FrameViewer;
